import React from 'react'
import "./style.css"

const Header = () => {
  return (
    <>
    <nav
        class="navbar navbar-expand-lg"
        style={{ backgroundColor: "#18164c",position:"fixed",width:"100%",zIndex: 999}}
      >
        <div class="container-fluid">
          <a class="navbar-brand pt-3 pb-3" href="/">
            <img
              class="mx-4 image"
              src="meshit.svg"
              width="150px"
              alt="meshit"
              srcset=""
              style={{marginLeft:"220px!important"}}
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="d-flex flex-row-end bd-highlight">
            <div class="p-2 bd-highlight">
              <a
                href="https://nutzindia.com"
                target="_blank"
                rel="noreferrer"
                class="mb-0 test1"
                
                style={{
                  textAlign: "center",
                  color: "white",
                  textDecoration: "none",
                  paddingRight: "30px",
                 
                }}
              >
                A Product by  <span class="bold">Nutz</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    
    </>
  )
}

export default Header