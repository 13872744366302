
import './App.css';
import Footer from './components/Common/Footer';
import Header from './components/Common/Header';
import Mesh from './components/mesh';
function App() {
  return (
    <>
      <Header/>
      <Mesh/>
      <Footer/>
    </>
  );
}

export default App;
