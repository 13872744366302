import React from 'react'
import "./style.css"
const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <>

      <section className="footer-area pt-100 m" style={{ background: "rgb(24, 22, 76)" }}>
        <div className="container mt-5 " >
          <div className="row" style={{ marginBottom: "150px" }}>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget"  >
                <div className="footer-logo" >
                  <a>
                    <img class="image1" src="meshit.svg" width="160px" alt="logo" style={{ marginTop: "40px" }} />
                  </a>
                  <p class="text" style={{ color: "white", marginTop: "20px", lineHeight: "2.5", textDecoration: "none!important" }}>
                    A Product by  <span class="bold"><a href="https://nutz.in/" target="_blank" style={{ textDecoration: "none", color: "white" }}>Nutz</a></span>
                  </p>
                </div>

              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5 list1" style={{ marginTop: "40px" }}>
                <h3 style={{ color: "white", fontSize: "20px" }}>Our Products</h3>
                <ul className="quick-as" style={{ paddingLeft: "0px" }} >
                  <li style={{ listStyle: "none", lineHeight: "2.5", marginTop: "15px" }} >
                    <a style={{ color: "white", textDecoration: "none" }} href="https://sportswander.com/">Sports Wander</a>
                  </li >
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutcommerz.in/">NutCommerZ </a>
                  </li>
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.page/">Nutz Pages</a>
                  </li>


                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5 list1" style={{ marginTop: "40px" }}>
                <h3 style={{ color: "white", fontSize: "20px" }}>Our Services</h3>
                <ul className="quick-as" style={{ paddingLeft: "0px" }} >
                  <li style={{ listStyle: "none", lineHeight: "2.5", marginTop: "15px" }} >
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/GraphicDesign">Designing</a>
                  </li >
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/Development">Development </a>
                  </li>
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/security">Security</a>
                  </li>


                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5 list1" style={{ marginTop: "40px" }}>
                <h3 style={{ color: "white", fontSize: "20px" }}>Company</h3>
                <ul className="quick-as" style={{ paddingLeft: "0px" }} >
                  <li style={{ listStyle: "none", lineHeight: "2.5", marginTop: "15px" }} >
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/about">About</a>
                  </li >
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/careers">Careers </a>
                  </li>
                  <li style={{ listStyle: "none", lineHeight: "2.5" }}>
                    <a style={{ color: "white", textDecoration: "none" }} href="https://nutz.in/training">Training</a>
                  </li>


                </ul>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
              <h3>Social  </h3>
               
               <ul className="footer-social">
                 <li>
                   <a target="_blank">
                     <i className="flaticon-facebook"></i>
                   </a>
                 </li>
                 <li>
                   <a target="_blank">
                     <i className="flaticon-twitter"></i>
                   </a>
                 </li>
                 <li>
                   <a target="_blank">
                   <i class="fas fa-envelope"></i> 
                   </a>
                 </li>
             
               </ul>
              </div>
            </div> */}

          </div>
          <div className="footer-bothrefm-area" style={{ marginTop: "-120px" }}>
            <div className="row align-items-center" >
              <div className="col-lg-12 col-md-12 footer"
                style={{ color: "white", display: "flex", justifyContent: "space-between", marginBottom: "50px" }}>

                <a class="foot" href="/" style={{ color: "white", textDecoration: "none" }}> Copyright © {currentYear}  <span class="nutz" ><strong><a href="https://nutz.in/" target="_blank" style={{ textDecoration: "none", color: "white" }}> Nutz.</a></strong></span></a>

                <span class="rights">
                  All Rights Reserved
                </span>
              </div>
            </div>
          </div>
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6"></div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default Footer