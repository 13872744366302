import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./style.css";
import FileSaver from "file-saver";
import image from "../components/images/ic1.png"

const Mesh = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState("");
  const [image, setImage] = useState({
    Meshit: "",
  });
  const [err, setErr] = useState("");
  const [meshdata, setMeshData] = useState("");
  const ref = useRef();
  console.log(meshdata);

  useEffect(() => {
    const uploadArea = document.querySelector("#uploadArea");

    const dropZoon = document.querySelector("#dropZoon");

    const loadingText = document.querySelector("#loadingText");

    const fileInput = document.querySelector("#fileInput");

    const previewImage = document.querySelector("#previewImage");

    const fileDetails = document.querySelector("#fileDetails");

    const uploadedFile = document.querySelector("#uploadedFile");

    const uploadedFileInfo = document.querySelector("#uploadedFileInfo");

    const uploadedFileName = document.querySelector(".uploaded-file__name");

    const uploadedFileIconText = document.querySelector(
      ".uploaded-file__icon-text"
    );

    const uploadedFileCounter = document.querySelector(
      ".uploaded-file__counter"
    );

    const toolTipData = document.querySelector(".upload-area__tooltip-data");

    const imagesTypes = ["jpeg", "png"];

    toolTipData.innerHTML = [...imagesTypes].join(", .");

    dropZoon.addEventListener("dragover", function (event) {
      event.preventDefault();

      dropZoon.classList.add("drop-zoon--over");
    });

    dropZoon.addEventListener("dragleave", function (event) {
      dropZoon.classList.remove("drop-zoon--over");
    });

    dropZoon.addEventListener("drop", function (event) {
      event.preventDefault();

      dropZoon.classList.remove("drop-zoon--over");

      const file = event.dataTransfer.files[0];

      uploadFile(file);
    });

    fileInput.addEventListener("change", function (event) {
      const file = event.target.files[0];

      uploadFile(file);
    });

    function uploadFile(file) {
      const fileReader = new FileReader();
      const fileType = file.type;
      const fileSize = file.size;

      if (fileValidate(fileType, fileSize)) {
        dropZoon.classList.add("drop-zoon--Uploaded");

        loadingText.style.display = "block";
        previewImage.style.display = "none";

        uploadedFile.classList.remove("uploaded-file--open");
        uploadedFileInfo.classList.remove("uploaded-file__info--active");

        fileReader.addEventListener("load", function () {
          setTimeout(function () {
            uploadArea.classList.add("upload-area--open");

            loadingText.style.display = "none";
            previewImage.style.display = "block";

            fileDetails.classList.add("file-details--open");
            uploadedFile.classList.add("uploaded-file--open");
            uploadedFileInfo.classList.add("uploaded-file__info--active");
          }, 500);

          previewImage.setAttribute("src", fileReader.result);

          uploadedFileName.innerHTML = file.name;

          progressMove();
        });

        fileReader.readAsDataURL(file);
      } else {
      }
    }

    function progressMove() {
      let counter = 0;

      setTimeout(() => {
        let counterIncrease = setInterval(() => {
          if (counter === 100) {
            clearInterval(counterIncrease);
          } else {
            counter = counter + 10;
            uploadedFileCounter.innerHTML = `${counter}%`;
          }
        }, 100);
      }, 600);
    }

    function fileValidate(fileType, fileSize) {
      return true;
    }
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}`).then((res) => {
      //   console.log(process.env.REACT_APP_URL);
      setData(res.data.data);
    });
  }, []);
  function handleClick(e) {
    setLoad(true);
    e.preventDefault();
    const formData = new FormData();
    Object.entries(image).map(([key, value]) => formData.append(key, value));
    axios
      .post("https://v1.meshit.in", formData)
      .then((res) => {
        //  console.log("response",res.data.Error);
        setLoad(false);
        setErr(res.data.Error);
        setMeshData(res.data.data);
      })
      .catch((err) => {
        // console.log("error", err);
        // setErr(err);
      });
  }
  //   console.log("Meshdata", meshdata);
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage({ ...image, [e.target.name]: e.target.files[0] });
    }
  };

  const imgData = (imagePath) => {
    let fileName = getFileName(imagePath);
    imagePath = 'https://v1.meshit.in' + imagePath
    // console.log(fileName);
    // console.log(imagePath);
    FileSaver.saveAs(imagePath, fileName);

    function getFileName(str) {
      return str.substring(str.lastIndexOf("/") + 1);
    }
  };
  const copyData = (imagePath) => {
    imagePath = 'https://v1.meshit.in' + imagePath
    navigator.clipboard.writeText(imagePath);
    window.$("h5").text("URL copied!");
    setTimeout(() => {
      window.$("h5").text("");
    }, 3000);
  };



  return (
    <>


      <div class="pt-5 margin">
        <div
          style={{ paddingTop: "116px", paddingBottom: "39px" }}
          class="container header"
        >
          <div class="row ">
            <form
              method="post"
              action=""
              enctype="multipart/form-data"
              id="myform"
            >
              <div id="uploadArea" class="upload-area">
                <input type="hidden" onChange={imageChange} value="dfsdf" />
                <div class="upload-area__header">
                  <h4
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    Upload your File
                  </h4>
                  <p style={{ fontWeight: "bold" }} />
                  Upload Limit is set to <b>(50 MB)</b> for Pre-release Version
                  <p class="upload-area__paragraph" style={{ color: "black" }}>
                    File should be an Image
                    <strong
                      style={{ color: "rgb(0, 0, 0)", fontWeight: "bold" }}
                    >
                      (.JPEG & .PNG)
                      <span class="upload-area__tooltip-data"></span>
                    </strong>
                  </p>
                </div>

                <div
                  id="dropZoon"
                  class="upload-area__drop-zoon drop-zoon"
                  onClick={() => ref.current.click()}
                >
                  <span class="drop-zoon__icon">
                    <i class="bx bxs-file-image"></i>
                  </span>
                  <p class="drop-zoon__paragraph">
                    Drop your file here or Click to browse {data}
                  </p>
                  <span id="loadingText" class="drop-zoon__loading-text">
                    Please Wait
                  </span>
                  <img
                    src=""
                    alt="Preview Image"
                    id="previewImage"
                    class="drop-zoon__preview-image"
                    draggable="false"
                  />
                  <input
                    type="file"
                    id="fileInput"
                    onChange={imageChange}
                    ref={ref}
                    name="Meshit"
                    required
                    class="drop-zoon__file-input"
                    accept="image/*"
                  />
                </div>

                <div
                  id="fileDetails"
                  class="upload-area__file-details file-details"
                >
                  <h3
                    style={{ textAlign: "center", paddingTop: "10px" }}
                    class="file-details__title"
                  ></h3>
                  <div id="uploadedFile" class="uploaded-file">
                    <div class="uploaded-file__icon-container">
                      <i class="bx bxs-file-blank uploaded-file__icon"></i>
                      <span class="uploaded-file__icon-text"></span>
                    </div>
                    <div id="uploadedFileInfo" class="uploaded-file__info">
                      <span class="uploaded-file__name">Project 1</span>
                      <span class="uploaded-file__counter">0%</span>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="row">
                      <button
                        class="btn btn-dark btn-lg fluid"
                        onClick={handleClick}
                        style={{
                          backgroundColor: "#18164c",
                          fontWeight: "bold",
                        }}
                        type="button"
                        id="but_upload"
                      >
                        Compress
                      </button>
                    </div>
                    {
                      load ? <center class="mt-5" style={{ fontSize: "19px" }} > Good Things Takes Time  <span >❤️</span>  </center>
                        : false
                    }
                  </div>
                </div>
                <div class="pt-3"></div>
                {load ? (
                  <div
                    class="spinner-border"
                    style={{ color: "#18164c" }}
                    role="status"
                    id="spinner"
                  >
                    <span class="visually-hidden">Loading...</span>

                  </div>
                ) : (
                  //     <div id="floatingBarsG">

                  //     <div class="blockG" id="rotateG_01"></div>
                  //      <div class="blockG" id="rotateG_02"></div>
                  //      <div class="blockG" id="rotateG_03"></div>
                  //      <div class="blockG" id="rotateG_04"></div>
                  //      <div class="blockG" id="rotateG_05"></div>
                  //      <div class="blockG" id="rotateG_06"></div>
                  //      <div class="blockG" id="rotateG_07"></div>
                  //      <div class="blockG" id="rotateG_08"></div>
                  //  </div>
                  false
                )}

                {err ? (
                  <div id="danger">
                    <div class="pb-2"></div>
                    <div
                      id="errorResponse"
                      style={{ textAlign: "center" }}
                      class="alert alert-danger"
                      role="alert"
                    >
                      {err}
                    </div>
                  </div>
                ) : (
                  false
                )}

                <div>
                  <div class="pb-0"></div>
                  {meshdata?.filename == null ? (
                    false
                  ) : (
                    <div
                      id="success"
                      style={{ textAlign: "center" }}
                      class="alert alert-success"
                      role="alert"
                    >
                      Click on the Image or Download Button to Download.
                    </div>
                  )}
                  <table class="table table-borderless">
                    <tbody>
                      <tr class="direction">
                        <td>
                          <a href={meshdata?.file} target="_blank" download>
                            <img
                              class="image2"
                              src={'https://v1.meshit.in' + meshdata?.file}
                              style={{ borderRadius: "11px" }}
                              alt=""
                              width="300px"
                            />
                          </a>
                        </td>
                        {meshdata?.filename == null ? (
                          false
                        ) : (
                          <td class="justify" style={{ textAlign: "justify" }}>
                            <p>
                              File Name:{" "}
                              <span id="name">{meshdata?.filename}</span>
                            </p>
                            <p>
                              Dimension:{" "}
                              <span id="size">{meshdata?.dimension}</span>
                            </p>
                            <p>
                              Mime Type:{" "}
                              <span id="mime">{meshdata?.mimetype}</span>
                            </p>
                            <p>
                              Original Size:{" "}
                              <span id="old">{meshdata?.oldsize}</span>
                            </p>
                            <p>
                              Meshit Size:{" "}
                              <span id="new">{meshdata?.newsize}</span>
                            </p>
                            <p>
                              Embed-URL:
                              <button
                                class="btn btn-dark btn-sm"
                                style={{
                                  backgroundColor: "#18164c",
                                  fontWeight: "bold",
                                  marginLeft: "10px"
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  copyData(meshdata?.file);
                                }}
                              >
                                <img src="ic1.png" style={{ width: "40px", marginLeft: "-8px" }} alt="copy" />Copy URL
                              </button>

                              <h5 style={{ marginLeft: "10px", marginTop: "20px" }}></h5>
                            </p>

                            <div class="pt-3"></div>
                            <button
                              class="btn btn-dark btn-lg"
                              style={{
                                backgroundColor: "#18164c",
                                fontWeight: "bold",
                              }}
                              onClick={(e) => {
                                console.log(meshdata?.file);
                                e.preventDefault();
                                imgData(meshdata?.file);
                              }}
                            >
                              <i class="bx bxs-download"></i> Download Image
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mesh;
